import { globalCss } from './stitches.config';

export const globalStyles = globalCss({
  body: {
    fontFamily: '$body',
  },
  '.sr-only': {
    position: 'absolute',
    margin: '-1px 0 0 -1px',
    padding: 0,
    display: 'block',
    width: '1px',
    height: '1px',
    fontSize: '1px',
    lineHeight: '1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
    outline: 0,
  },
});
