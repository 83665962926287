import { NotificationsQuery, Maybe } from '@generated/graphql';

type BaseNotification = NonNullable<NonNullable<NotificationsQuery['notificationCollection']>['items'][number]>;

export type NotificationType = Omit<BaseNotification, 'level'> & {
  level: 'warning' | 'error' | 'success' | 'info';
};

export const isNotificationItem = (item: Maybe<BaseNotification>): item is NotificationType => {
  return (
    !item ||
    !item.title ||
    !item.message ||
    !item.level ||
    item.level === 'warning' ||
    item.level === 'error' ||
    item.level === 'success' ||
    item.level === 'info' ||
    item.__typename === 'Notification'
  );
};
