/* eslint-disable @typescript-eslint/ban-types */
import { createStitches, CSS as StitchesCSS, ScaleValue } from '@stitches/react';
export type { VariantProps } from '@stitches/react';

const BASE_FONT_SIZE = 16;

const screens = {
  xxs: 360,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

const space = {
  px: '1px',
  0: '0px',
  '0_5': '0.125rem',
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
};

export const stitches = createStitches({
  theme: {
    colors: {
      primary50: '#E6E0EF',
      primary100: '#9D8FB0',
      primary200: '#7B6895',
      primary300: '#5F487E',
      primary400: '#572179',

      secondary50: '#FFF6E9',
      secondary100: '#F3D1A2',
      secondary200: '#EDBE80',
      secondary300: '#E7A960',
      secondary400: '#F59C00',

      tertiary50: '#CDE6F0',
      tertiary100: '#9DD0E6',
      tertiary200: '#6CBEDF',
      tertiary300: '#22AED9',
      tertiary400: '#009FE3',

      quartenary50: '#E1ECD0',
      quartenary100: '#C8DBAB',
      quartenary200: '#ACCB8A',
      quartenary300: '#93BE6E',
      quartenary400: '#76B82A',

      white: '#FFFFFF',
      black: '#000000',

      gray50: '$white',
      gray100: '#EFEFEF',
      gray200: '#DCDCDC',
      gray300: '#B7B7B7',
      gray400: '$black',

      success50: '#E6FAF3',
      success100: '#21A67A',
      success200: '#006647',

      notification50: '#EAF7FD',
      notification100: '#2B9DD6',
      notification200: '#007BC2',

      warning50: '#FFF4E0',
      warning100: '#F0A92E',
      warning200: '#CC860C',

      error50: '#FFF0ED',
      error100: '#EF3934',
      error200: '#B50600',
    },
    space: space,
    sizes: space,
    shadows: {
      1: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    },
    fontSizes: {
      xxs: rem(10),
      xs: rem(12),
      sm: rem(14),
      base: rem(16),
      lg: rem(18),
      xl: rem(20),
      '2xl': rem(24),
      '3xl': rem(28),
      '4xl': rem(32),
      '5xl': rem(36),
      '6xl': rem(40),
    },
    fonts: {
      heading: 'Roboto Slab, $systemSerif',
      body: '"Inter", $systemSans',
      systemSans: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      systemSerif: `ui-serif, Georgia, Cambria, "Times New Roman", Times, serif`,
    },
    fontWeights: {
      bodySemiBold: 500,
    },
    radii: {
      0: 0,
      1: rem(2),
      2: rem(4),
      full: '9999px',
    },
  },
  media: {
    light: '(prefers-color-scheme: light)',
    dark: '(prefers-color-scheme: dark)',
    hmd: '(min-height: 480px)',
    ...Object.fromEntries(Object.entries(screens).map(([name, size]) => [name, `(min-width: ${size}px)`])),
    ...Object.fromEntries(Object.entries(screens).map(([name, size]) => [`<${name}`, `(max-width: ${size - 1}px)`])),
  },
  utils: {
    ring: () => ({
      $$ringBoxShadow: '0 0 0 0px #fff',
      $$ringContainer: '0 0 0 0px #fff',
      $$ringWidth: '$space$1',
      $$ringColor: '$colors$tertiary300',
      '--ring-inset': 'var(--empty-var, /* empty */)',
      boxShadow: '$$ringContainer, $$ringBoxShadow',

      '&:focus-visible': {
        $$ringContainer: `var(--ring-inset) 0 0 0 $$ringWidth $$ringColor`,
        outline: 0,
      },
    }),
    ringInset: () => ({
      '--ring-inset': 'inset',
    }),

    size: (value: ScaleValue<'space'>) => ({
      width: value,
      height: value,
    }),

    m: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      marginTop: value,
    }),
    mr: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      marginRight: value,
    }),
    mb: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      marginBottom: value,
    }),
    ml: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      marginLeft: value,
    }),
    mx: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      marginTop: value,
      marginBottom: value,
    }),

    p: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      paddingTop: value,
    }),
    pr: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      paddingRight: value,
    }),
    pb: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      paddingBottom: value,
    }),
    pl: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      paddingLeft: value,
    }),
    px: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: ScaleValue<'space'> | ((string | number) & {})) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    spaceX: (value: ScaleValue<'space'>) => ({
      '& > * + *': {
        marginLeft: value,
      },
    }),
    spaceY: (value: ScaleValue<'space'>) => ({
      '& > * + *': {
        marginTop: value,
      },
    }),

    lineClamp: () => (value: number) => ({
      display: '-webkit-box',
      lineClamp: value,
      WebkitLineClamp: value,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }),
  },
  prefix: '',
});

export const { styled, css, theme, getCssText, globalCss, keyframes, config } = stitches;
export type CSS = StitchesCSS<typeof stitches>;

function rem(px: number) {
  return `${px / BASE_FONT_SIZE}rem`;
}
