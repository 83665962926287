import { READ_NOTIFICATIONS_STORAGE_KEY } from '@src/constants';
import { StorageProvider } from './storage-provider';

export class NotificationStorageProvider {
  private provider: StorageProvider;
  readonly STORAGE_KEY = READ_NOTIFICATIONS_STORAGE_KEY;

  public constructor(provider: StorageProvider) {
    this.provider = provider;
  }

  public setReadNotifications(notifications: string[]): void {
    this.provider.set(this.STORAGE_KEY, JSON.stringify(notifications));
  }

  public getReadNotifications(): string[] {
    if (!this.provider.has(this.STORAGE_KEY)) return [];

    let notifications = this.provider.get(this.STORAGE_KEY);

    try {
      notifications = JSON.parse(this.provider.get(this.STORAGE_KEY));
      if (!Array.isArray(notifications)) {
        throw new Error('Stored notifications is not of type Array');
      }
    } catch (error) {
      this.provider.set(this.STORAGE_KEY, JSON.stringify([]));

      return [];
    }

    return notifications;
  }
}
