export const MAX_AGE = {
  refresh_token: 60 * 15, // 15 minutes,
  access_token: 60 * 3, // 3 minutes,
  verify_token: 60 * 5, // 5 minutes,
  generic: 60 * 60 * 24 * 7, // 1 week,
  year: 60 * 60 * 24 * 365, // 1 year
};

export enum UserRole {
  OPERATIONS = 'customer_operations',
  SUPPORT = 'customer_support',
  DEMAND = 'customer_demand',
  GUEST = 'customer_guest',
  NOTARY = 'customer_notary',
}
