import { useEffect, useState, createContext, useContext, useCallback, useMemo } from 'react';
import { NotificationStorageProvider, StorageProvider } from '@lib/storage';
import { NotificationCollectionDataFragment } from '@src/generated/graphql';
import { isNotificationItem, NotificationType } from '@src/types/notification';
import { useQuery } from 'react-query';
import { useSession } from 'next-auth/react';

type NotificationContextValue = {
  allNotifications: NotificationCollectionDataFragment['items'] | undefined;
  unreadNotifications: NotificationType[];
  setNotificationsRead: () => void;
};

const NotificationContext = createContext<NotificationContextValue>({
  allNotifications: undefined,
  unreadNotifications: [],
  setNotificationsRead() {
    throw new Error('NotificationContext is not initialized');
  },
});

type NotificationContextProps = React.PropsWithChildren<{
  provider: StorageProvider;
}>;

const extractSysId = ({ sys: { id } }: NotificationType): string => id;

async function getNotifications() {
  const res = await fetch('/api/notifications');

  return res.json() as Promise<NotificationCollectionDataFragment['items']>;
}

export function NotificationContextProvider({ provider, children }: NotificationContextProps): JSX.Element {
  const session = useSession();
  const notificationProvider = useMemo(() => new NotificationStorageProvider(provider), [provider]);
  const [unreadNotifications, setUnreadNotifications] = useState<NotificationType[]>([]);
  const [readNotificationIds, setReadNotificationIds] = useState<string[]>([]);

  const { data: allNotifications } = useQuery('notifications', getNotifications, {
    enabled: !!session.data,
  });

  useEffect(() => {
    if (!allNotifications) {
      return;
    }

    setUnreadNotifications(
      allNotifications.flatMap((i) => {
        if (i === null || !i) {
          return [];
        }

        return !isNotificationItem(i) || notificationProvider.getReadNotifications().includes(extractSysId(i)) ? [] : i;
      }),
    );
  }, [allNotifications, notificationProvider, readNotificationIds]);

  const setNotificationsRead = useCallback(() => {
    const mappedIds = [...new Set(unreadNotifications.map(extractSysId).concat(notificationProvider.getReadNotifications())).values()];

    notificationProvider.setReadNotifications(mappedIds);
    setReadNotificationIds(mappedIds);
  }, [notificationProvider, unreadNotifications]);

  const contextValue = useMemo(
    () => ({ allNotifications, unreadNotifications, setNotificationsRead }),
    [allNotifications, unreadNotifications, setNotificationsRead],
  );

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
}

export function useNotifications(): NotificationContextValue {
  return useContext(NotificationContext);
}
