import * as React from 'react';
import { createCookie } from '@lib/cookies';
import { MENU_PREFERENCE } from '@src/constants';

type PreferenceContextValue = {
  collapsedMenu: boolean;
  setCollapsedMenu: (collapsedMenu: boolean) => void;
};

const PreferenceContext = React.createContext<PreferenceContextValue>({
  collapsedMenu: false,
  setCollapsedMenu() {
    throw new Error('PreferenceContext is not initialized');
  },
});

type PreferenceContextProps = React.PropsWithChildren<{
  preferMenuCollapsed?: boolean;
}>;

export function PreferenceContextProvider({ preferMenuCollapsed, children }: PreferenceContextProps): JSX.Element {
  const [collapsedMenu, setCollapsedMenu] = React.useState(preferMenuCollapsed ?? false);

  React.useEffect(() => {
    document.cookie = createCookie(MENU_PREFERENCE, collapsedMenu ? '1' : '0', 'generic', false);
  }, [collapsedMenu]);

  const contextValue = React.useMemo(() => ({ collapsedMenu, setCollapsedMenu }), [collapsedMenu, setCollapsedMenu]);

  return <PreferenceContext.Provider value={contextValue}>{children}</PreferenceContext.Provider>;
}

export function usePreferenceContext(): PreferenceContextValue {
  return React.useContext(PreferenceContext);
}
