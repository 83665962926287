export const ROUTES = {
  announcement: '/portal/announcements',
  announcementCategory: (category: string): string => `/portal/announcements?category=${category.toLowerCase()}`,
  announcementDetail: (id: string): string => `/portal/announcements/${id}`,
  home: '/portal',
  eventDetail: (id: string): string => `/portal/calendar/${id}`,
  events: '/portal/calendar',
  faq: (category: string): string => `/portal/faq/${category.toLowerCase()}`,
  faqSubcategory: (category: string, subcategory: string): string => `/portal/faq/${category.toLowerCase()}?cat=${subcategory.toLowerCase()}`,
  login: '/login',
  logout: '/logout',
  messages: '/portal/messages',
  news: '/portal/news',
  newsDetail: (slug: string): string => `/portal/news/${slug}`,
  products: '/portal/products',
  profile: '/portal/profile',
  riskCompliance: '/portal/risk-and-compliance',
  profileNotifications: '/portal/profile/notifications',
  profileSettings: '/portal/profile/settings',
  ticketDetail: (id: string): string => `/portal/tickets/${id}`,
  tickets: '/portal/tickets',
};
