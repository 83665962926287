export interface StorageProvider {
  set(key: string, value: string): void;
  get(key: string): string;
  has(key: string): boolean;
}

export class LocalStorageProvider implements StorageProvider {
  set(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }
  get(key: string): string {
    const value = window.localStorage.getItem(key);

    if (value === null) {
      throw new Error('Key ' + key + ' not found in localStorage');
    }

    return value;
  }
  has(key: string): boolean {
    try {
      return !!this.get(key);
    } catch (e) {}

    return false;
  }
}
