import NextApp, { AppContext as NextAppContext, AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { QueryClientProvider, QueryClient } from 'react-query';
import '@src/polyfills';
import '@styles/main.css';
import '@styles/global-styles';
import { getMenuCollapsedPreferenceCookie } from '@lib/cookies';
import { globalStyles } from '@styles/global-styles';
import { MAX_AGE } from '@src/constants';
import { PreferenceContextProvider } from '@src/contexts/preference-context';
import { NotificationContextProvider } from '@src/contexts/notification-context';
import { LocalStorageProvider } from '@lib/storage';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation, SSRConfig } from 'next-i18next';
import nextI18nConfig from '../../next-i18next.config';

const queryClient = new QueryClient();

type AppProps = NextAppProps & {
  menuCollapsedCookie: boolean;
  pageProps: SSRConfig;
};

function App({ Component, pageProps, menuCollapsedCookie }: AppProps): JSX.Element {
  globalStyles();

  return (
    <>
      <Head>
        <title>Stater</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <SessionProvider refetchInterval={MAX_AGE.access_token}>
          <PreferenceContextProvider preferMenuCollapsed={menuCollapsedCookie}>
            <NotificationContextProvider provider={new LocalStorageProvider()}>
              <Component {...pageProps} />
            </NotificationContextProvider>
          </PreferenceContextProvider>
        </SessionProvider>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation<AppProps>(App, nextI18nConfig);

App.getInitialProps = async (context: NextAppContext) => {
  let initialData = {};
  const appProps = await NextApp.getInitialProps(context);

  if (typeof window === 'undefined') {
    const menuCollapsedCookie = getMenuCollapsedPreferenceCookie(context.ctx.req?.headers.cookie ?? '');

    initialData = {
      ...appProps,
      menuCollapsedCookie,
    };

    return initialData;
  }

  return { initialData };
};
