import { parse as parseCookies, serialize } from 'cookie';
import { MENU_PREFERENCE, MAX_AGE } from '@src/constants';

export function getMenuCollapsedPreferenceCookie(cookie: string): boolean {
  const cookies = parseCookies(cookie);

  return Boolean(Number(cookies[MENU_PREFERENCE]));
}

export function createCookie(name: string, content: string, maxAge: keyof typeof MAX_AGE = 'generic', httpOnlyCookie = true): string {
  const httpOnly = httpOnlyCookie;
  const cookie = serialize(name, content, {
    maxAge: MAX_AGE[maxAge],
    expires: new Date(Date.now() + MAX_AGE[maxAge]),
    httpOnly,
    secure: process.env.NODE_ENV === 'production',
    path: '/',
  });

  return cookie;
}
