const path = require('path');
const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;
const localePath = path.resolve('./public/locales');

const isBrowser = typeof window !== 'undefined';

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  debug: false,
  i18n: {
    locales: ['nl', 'en', 'de'],
    defaultLocale: 'nl',
  },
  returnNull: false,
  preload: ['en', 'nl'],
  fallbackLng: 'en',
  defaultNS: 'HomePage',
  saveMissing: false,
  backend: {
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
    backendOptions: [
      {
        expirationTime: process.env.NODE_ENV === 'development' ? 30 * 1000 : 60 * 60 * 1000,
      },
      {
        loadPath: `/api/locales/{{lng}}/{{ns}}.json`,
      },
    ],
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged loaded',
  },
  localePath,
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  use: isBrowser ? [ChainedBackend] : [],
};
